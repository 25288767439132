<template>
  <div class="composite-material-detail">
    <div class="collection">
      <div class="collection-top col-center">
        <!-- :style="'width:'+ imageWidth + ';height:' +  imageHeight +';margin: 0 21px;'" -->
        <div
          class="row-center"
          :style="'width:100%'"
        >
          <!-- <img
            class="collection-top-img"
            :src="collectionInfo.image"
          /> -->
          <!-- <goods-item
            :goodsType="'img'"
            :goodsSrc="collectionInfo.image"
          ></goods-item> -->
          <goods-item
            :goodsType="materialInfo.productAssetsSkuVo.pid ? mediaType[materialInfo.productAssetsSkuVo.pid] : 'img'"
            :goodsSrc="materialInfo.productAssetsSkuVo.image"
            :goodsSource="materialInfo.productAssetsSkuVo.sourceFile"
            :goodsPoster="materialInfo.productAssetsSkuVo.coverImg"
            :goodsBackground="materialInfo.productAssetsSkuVo.backgroundImg"
          ></goods-item>
        </div>

      </div>
      <div class="collection-infos">
        <p class="collection-top-name">{{ materialInfo.name }}</p>
        <div class="collection-id">ID:{{ materialInfo.uniqueCode }}</div>

      </div>
      <div class="collection-man row-between">
        <!-- <p class="collection-man-title">收藏者</p>
        <div class="collection-man-content">
          <p class="collection-man-name">{{ collectionInfo.uname }}</p>
          <p class="collection-man-id">{{ collectionInfo.uchainName }}</p>
        </div> -->
        <van-cell-group style="width: 100%">
          <van-field
            label="状态"
            :value="'已销毁'"
            readonly
          />
          <van-field
            label="作者"
            :value="materialInfo.user.realName"
            readonly
          />
          <van-field
            label="合成时间"
            :value="materialInfo.submitTime"
            readonly
          />

        </van-cell-group>
      </div>

      <!-- <div class="collection-author row-between">
        <p class="collection-author-title">作者</p>
        <div
          style="width:80%;"
          class="row-start"
        >
          <div :style="'width: 3rem;height: 3rem;background-image: url('+ collectionInfo.facImg +');background-repeat: no-repeat;background-attachment: scroll;background-position: 0% 0%;background-size: 100% auto;background-color: transparent;'"></div>
          <p
            style="margin-left: 1.1875rem;"
            class="collection-man-name"
          >{{ collectionInfo.facName }}</p>
        </div>
      </div>

      <div class="collection-author row-between">
        <p class="collection-author-title">发布时间</p>
        <p class="collection-time">{{ collectionInfo.issueTime }}</p>
      </div> -->

      <!-- <p class="collection-tip">提示：本数字藏品的知识产权或其他权益属发行方或权利人所有，除另行取得发行方或权利人授权外，您不得将数字藏品用于任何商业用途。请远离非理性炒作，防范欺诈风险。</p>
      <p class="copyright">区块链技术由智链2.0提供支持</p> -->
      <p style="margin-top:3.125rem;"></p>
    </div>
    <!-- <div
      @click="donationCollection()"
      class="collection-footer row-center"
    >
      <img :src="collectionInfo.chainStatus == 0 ? require('../assets/icon/no-send.png') : require('../assets/icon/collection-send.png')" />
      <p :style="collectionInfo.chainStatus == 0 ? 'color: #666666;' : 'color: #ffffff;'">转 赠{{ collectionInfo.chainStatus == 0 ? '  (上链中)' : '' }}</p>
    </div> -->
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <el-dialog
      title="尚未设置资产密码"
      :visible.sync="donationVisible"
      @confirm="$router.push('/resetPwd')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="donationVisible = false">取 消</el-button>
        <div
          @click="$router.push('/resetPwd')"
          class="confirm-button row-center"
        >去设置</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="donationErrorVisible"
      @confirm="donationErrorVisible = false"
      class="donation-dialog"
      center
    >
      <p class="donation-error-title">{{ donationErrorTitle }}</p>
      <p class="donation-error-tip">{{ donationErrorTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="donationErrorVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >点击右上角按钮，然后在弹出的菜单中，点击在浏览器打开，转赠</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import GoodsItem from '../components/GoodsItem.vue'
import Cookies from 'js-cookie'
import Vue from 'vue'
import { Field,CellGroup } from 'vant';

Vue.use(Field);
Vue.use(CellGroup);
import config from '../config'
import { Button,Dialog } from 'element-ui';
Vue.use(Button)
Vue.use(Dialog)
export default {
  components: {
    GoodsItem
  },
  data () {
    return {
      browerOpenVisible: false,
      donationVisible: false,
      authVisible: false,
      donationErrorVisible: false,
      donationErrorTitle: '12312312321',
      donationErrorTip: '',
      imageWidth: '',
      imageHeight: '',
      collectionInfo: {
        image: '',
        name: '',
        skuNo: '',
        uname: '',
        uniqueCode: '',
        facImg: '',
        facName: '',
        issueTime: '',
        chainStatus: '',
      },
      hasRealname: false,
      mediaType: config.mediaType,
      materialInfo: {
        productAssetsSkuVo: {
          pid: 1007
        },
        user: {}
      }
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.imageWidth = this.imageHeight = (document.documentElement.clientWidth - 42) + 'px'
    // this.getCollectionInfo()
    this.materialInfo = JSON.parse(sessionStorage.getItem('materialInfo'))
    this.materialInfo.submitTime = this.materialInfo.submitTime ? `${new Date(this.materialInfo.submitTime).getFullYear()}/${new Date(this.materialInfo.submitTime).getMonth() + 1}/${new Date(this.materialInfo.submitTime).getDate()}` : ''
    this.getMyInfo()
  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.hasRealname = result.data.data.hasRealname
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 获取藏品信息
    getCollectionInfo () {
      api
        .post('assets/detail/' + this.$route.query.id)
        .then(result => {
          if (result.data.success) {
            this.collectionInfo = result.data.data
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 获取转赠藏品信息
    giftChooseEnd (id) {
      api
        .post('gift/choose/' + id)
        .then(result => {
          if (result.data.success) {
            this.$router.push('/donation?id=' + this.$route.query.id)
          } else if (result.data.status == '6003') {
            this.authVisible = true
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.show = false
            this.donationErrorVisible = true
            this.donationErrorTitle = result.data.status != '10007' && result.data.status != '10008' ? result.data.msg : result.data.msg.split('\n')[0]
            this.donationErrorTip = result.data.status == '10007' || result.data.status == '10008' ? result.data.msg.split('\n')[1] : ''
          }
        })
    },
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    donationCollection () {
      if (this.collectionInfo.chainStatus != 0) {
        if (Cookies.get('Device') == 'pico') {
          this.$toast({
            message: '请移步手机端进行转赠',
            icon: require('../assets/icon/toast-error.png'),
          });
        } else {
          // if (this.isWechat()) {
          //   this.browerOpenVisible = true
          // } else {
          this.giftChooseEnd(this.$route.query.id)
          // }

        }
      }

    },
    // 跳转ipfs浏览器
    goIpfsBrower () {
      // window.open(config.explorerUrlBase + str + url + '?from=zdChain')
      // 获取ipfs key
      api
        .post('ipfs/getCheckToken?skuNo=' + this.collectionInfo.skuNo)
        .then(result => {
          if (result.data.success) {

            window.open(config.ipfsBrowerHref + '/fileInfo.html?hash=' + this.collectionInfo.ipfsHash + '&checkToken=' + result.data.data)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    toUrl (str,url,err) {
      if (!url) return this.$toast.fail(`${err}不存在`)
      window.open(config.explorerUrlBase + str + url + '?from=zdChain')
    }
  },
}
</script>

<style lang="less" scoped>
.composite-material-detail {
  margin-top: 3.875rem;
  width: 100%;
  // height: 5rem;
  background: url('../assets/img/store/store_info.png') 50% 50% no-repeat;
  background-size: 100% 100%;
  .collection-top-name {
    font-size: 1.1875rem;
    font-weight: bold;
    // color: #292929;

    color: #ffffff;
    height: 1.6875rem;
    line-height: 1.6875rem;
    margin: 0 1.25rem;
    padding-top: 1rem;
    width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // text-align: center;
  }

  .collection-id {
    // width: 9.6875rem;
    height: auto;
    padding: 0.5rem 1.25rem 1rem;
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 200;
    // color: #292929;
    line-height: 1.0625rem;
    // background: #f2f2f2;
    border-radius: 0.125rem;
    word-break: break-all;

    color: #ffffff;
  }
}
.composite-material-detail {
  // margin-top: 3.875rem;
  height: 100vh;
  width: 100%;
  z-index: 19;
  .collection {
    height: calc(100vh - 3.125rem);
    overflow: auto;
  }
  .collection-top {
    width: 100%;
    height: auto;
    min-height: 15.625rem;
    background: #d0d0d0;
    // padding-bottom: 1.1875rem;
  }

  // #090A0A
  .collection-man {
    margin: 0.625rem 2% 0 2%;
    width: 96%;
    height: auto;
    padding: 1rem 0;
    background: #333333;
    border-radius: 0.5rem;
    z-index: 20;
  }

  .collection-author {
    margin: 0.625rem 2% 0 2%;
    width: 96%;
    height: 4.1875rem;
    background: #ffffff;
    border-radius: 0.25rem;
  }

  .collection-man-title {
    width: 30%;
    height: 3.75rem;
    margin-left: 0.875rem;
    margin-top: 0.3125rem;
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #333333;
    line-height: 1.0625rem;
  }

  .collection-author-title {
    width: 30%;
    margin-left: 0.875rem;
    margin-top: 0.3125rem;
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #333333;
    line-height: 1.0625rem;
  }

  .collection-man-content {
    width: 70%;
    height: 3.75rem;
    margin-right: 1.875rem;
  }

  .collection-man-name {
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #333333;
    line-height: 1.375rem;
  }

  .collection-man-id {
    margin-top: 0.3125rem;
    width: 100%;
    height: auto;
    font-size: 0.75rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.0625rem;
    word-break: break-all;
  }

  .collection-time {
    margin-top: 0.3125rem;
    width: 70%;
    height: auto;
    font-size: 0.75rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.0625rem;
    word-wrap: break-word;
  }

  .collection-top-img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0;
  }

  .van-cell-group {
    background: #333333;
    /deep/ .van-field__label {
      // color: #333333;
      color: #e6e6e6;
      font-size: 0.875rem;
      font-family: lantingheiweight;
    }
    /deep/ .van-field__body {
      input,
      textarea {
        color: #999999;
        font-size: 0.75rem;
        font-family: lantingheiweight;
      }
    }
    /deep/ .van-cell {
      background: #333333;
    }
    /deep/ .van-cell::after {
      border-bottom: 2px solid #3f3f3f;
    }
  }
  .copyright {
    font-weight: 500;
    color: #999999;
    margin-top: 1.1875rem;
    text-align: center;
    font-size: 0.75rem;
  }
  .collection-footer {
    height: 3.125rem;
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: #080808;
    z-index: 1998;
    -webkit-overflow-scroll: touch;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    img {
      width: 1.25rem;
      height: auto;
      margin-right: 0.6875rem;
      z-index: 1999;
    }
    p {
      font-size: 16px;
      font-family: lantingheiweight;
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
      z-index: 1999;
    }
    .collection-footer-tip {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #a9a9a9;
      line-height: 17px;
      z-index: 1999;
    }
  }
}
</style>